import React from "react";
import tw from "twin.macro";
import Inbox from "../assets/images/inbox.png";
import InboxMobile from "../assets/images/inboxMobile.png";

const TextContent = tw.div`justify-center items-center`;
const Problem = tw.div`w-11/12 mb-4`;
const Solution = tw.div`w-11/12 mt-3`;
const ProblemText = tw.div`w-11/12 text-xl md:text-2xl`;
const CTAText = tw.div`text-xl md:text-2xl mb-2 pt-4`;
const SolutionHeader = tw.div`w-11/12 text-3xl md:text-5xl text-primary-500`;
const PrimaryButtonBase = tw.button`px-1 py-1 text-primary-500 border-3 border-primary-500 border-solid hover:bg-primary-500 hover:text-gray-100 focus:outline-none`;
const PrimaryButton = tw(
    PrimaryButtonBase,
)`mt-2 text-2xl font-bold inline-block rounded-xl`;
const primaryButtonUrl = "https://production.uclusion.com/?utm_campaign=team#signup";

const BelowTheFoldScrum = (properties) => {
    const { isMobile } = properties;
    return (
        <TextContent style={{display:"flex", flexDirection:"column"}}>
            <Problem>
                <ProblemText style={{display: isMobile ? 'none' : undefined}}>
                    Constant meetings don't fit complex problems and break a day into pieces.
                </ProblemText>
            </Problem>
            <SolutionHeader>
                Wizard powered inbox.
            </SolutionHeader>
            <Solution>
                <img src={isMobile ? InboxMobile : Inbox} alt='Inbox'
                     style={{maxHeight: isMobile ? undefined : '30rem', }} />
                <CTAText>
                    See much, much more in the <PrimaryButton as="a" href={primaryButtonUrl}>sandbox demo</PrimaryButton> after
                    free sign up.
                </CTAText>
            </Solution>
        </TextContent>

    );
};

export default BelowTheFoldScrum;