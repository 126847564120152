import React from "react";
import tw from "twin.macro";
import "./bounce.css";
import BelowTheFoldDemoTeaser from "./BelowTheFoldDemoTeaser";
const Container = tw.div`pt-24 w-full flex justify-center items-center`;
const TextContent = tw.div`text-center md:text-left mr-5 ml-5`;
const Heading = tw.div`font-black text-left text-4xl lg:text-4xl text-center leading-tight tracking-wide`;
const SubHeading = tw.div`text-center font-normal h-24 lg:h-12`;
const CTA = tw.div`text-center font-normal text-2xl`;
const HeadingHighlight = tw.span`text-primary-500`;
const PrimaryButtonBase = tw.button`px-1 py-1 text-primary-500 border-3 border-primary-500 border-solid hover:bg-primary-500 hover:text-gray-100 focus:outline-none`;
const PrimaryButton = tw(
    PrimaryButtonBase,
)`mt-2 text-2xl font-bold inline-block rounded-xl`;
const primaryButtonUrl = "https://production.uclusion.com/?utm_campaign=team#signup";

const AboveTheFold = (properties) => {
    const { isMobile } = properties;

    return (
        <Container>
            <TextContent>
                <Heading style={{fontSize: isMobile ? '1.8rem' : undefined, marginTop: isMobile ? '2rem' : undefined}}>
                    Task management that's opinionated, asynchronous, and<br/>obsessed with <HeadingHighlight>useful code</HeadingHighlight>.
                </Heading>
                <SubHeading>
                    <CTA style={{paddingTop: isMobile ? '2rem': '1rem', fontSize: isMobile ? '1rem' : undefined}}>
                    <PrimaryButton as="a" href={primaryButtonUrl}>Signup</PrimaryButton> without credit card to
                        play with the sandbox shown in the video below.
                    </CTA>
                </SubHeading>
                <BelowTheFoldDemoTeaser isMobile={isMobile} />
            </TextContent>
        </Container>
    );
};

export default AboveTheFold;