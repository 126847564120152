import React from "react";
import tw from "twin.macro";
import AssignJob from "../assets/images/jobAssignment.png";
import AssignJobMobile from "../assets/images/assignJobMobile.png";

const TextContent = tw.div`justify-center items-center`;
const Problem = tw.div`w-11/12 mb-5`;
const Solution = tw.div`w-11/12 mt-3`;
const CTAText = tw.div`text-xl md:text-2xl mb-2 pt-6`;
const SolutionHeader = tw.div`w-11/12 text-3xl md:text-5xl text-primary-500 mt-2`;
const SolutionText = tw.div`w-11/12 text-xl md:text-2xl`;
const SolutionList = tw.ul`list-none mb-5 mt-5`;
const SolutionListEntry = tw.li`flex items-center mr-2 text-xl font-bold`;
const PrimaryButtonBase = tw.button`px-1 py-1 text-primary-500 border-3 border-primary-500 border-solid hover:bg-primary-500 hover:text-gray-100 focus:outline-none`;
const PrimaryButton = tw(
    PrimaryButtonBase,
)`mt-2 text-2xl font-bold inline-block rounded-xl`;
const CheckMark = tw.svg`w-4 h-4 text-primary-500 flex-shrink-0 mr-2`;
const primaryButtonUrl = "https://production.uclusion.com/?utm_campaign=team#signup";
const LeftAndRight = tw.div`flex justify-between`;
const RightPage = tw.div`w-8/12`;

const BelowTheFoldJira = (properties) => {
    const { isMobile } = properties;
    const check = <CheckMark aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                             viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
    </CheckMark>;
    return (
        <TextContent style={{display: "flex", flexDirection: "column"}}>
            <Problem>
                <SolutionText style={{display: isMobile ? 'none' : undefined}}>
                    Without workflows, ticketing tools force meetings.
                </SolutionText>
            </Problem>
            <SolutionHeader>
                Collaboration workflows.
            </SolutionHeader>
            <Solution>
                <LeftAndRight>
                    <SolutionList>
                        <SolutionListEntry style={{fontSize: isMobile ? '0.8rem' : undefined}}>
                            {check}
                            self assigning jobs</SolutionListEntry>
                        <SolutionListEntry style={{fontSize: isMobile ? '0.8rem' : undefined}}>
                            {check}
                            self assigning bugs</SolutionListEntry>
                        <SolutionListEntry style={{fontSize: isMobile ? '0.8rem' : undefined}}>
                            {check}
                            job approval</SolutionListEntry>
                        <SolutionListEntry style={{fontSize: isMobile ? '0.8rem' : undefined}}>
                            {check}
                            job estimation</SolutionListEntry>
                        <SolutionListEntry style={{fontSize: isMobile ? '0.8rem' : undefined}}>
                            {check}
                            job review</SolutionListEntry>
                        <SolutionListEntry style={{fontSize: isMobile ? '0.8rem' : undefined}}>
                            {check}
                            job assistance</SolutionListEntry>
                    </SolutionList>
                    <RightPage>
                        <img src={isMobile ? AssignJobMobile : AssignJob} alt='Assign job' />
                    </RightPage>
                </LeftAndRight>
                <CTAText>
                    See <em>administrivia</em> handled in the <PrimaryButton as="a" href={primaryButtonUrl}>sandbox demo</PrimaryButton> after
                    free sign up.
                </CTAText>
            </Solution>
        </TextContent>
    );
};

export default BelowTheFoldJira;
