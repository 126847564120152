import React from "react";
import tw from "twin.macro";
import Bugs from "../assets/images/bugs.png";
import BugsMobile from "../assets/images/bugsMobile.png";

const TextContent = tw.div`justify-center items-center mr-2 ml-5`;
const Problem = tw.div`w-11/12 mb-5`;
const Solution = tw.div`w-11/12 mt-8`;
const ProblemText = tw.div`w-11/12 text-xl md:text-2xl`;
const CTAText = tw.div`text-xl md:text-2xl mb-2 pt-6`;
const SolutionHeader = tw.div`w-full text-3xl text-primary-500`;
const PrimaryButtonBase = tw.button`px-1 py-1 text-primary-500 border-3 border-primary-500 border-solid hover:bg-primary-500 hover:text-gray-100 focus:outline-none`;
const PrimaryButton = tw(
    PrimaryButtonBase,
)`mt-2 text-2xl font-bold inline-block rounded-xl`;
const primaryButtonUrl = "https://production.uclusion.com/?utm_campaign=team#signup";

const BelowTheFoldScale = (properties) => {
    const { isMobile } = properties;
    return (
        <TextContent style={{display:"flex", flexDirection:"column"}}>
            <Problem>
                <ProblemText style={{display: isMobile ? 'none' : undefined}}>
                    Why force a customer to use email, chat, or issue trackers when they can have a private workspace?
                </ProblemText>
            </Problem>
            <SolutionHeader style={{fontSize: isMobile ? '1.5rem' : undefined}}>
                Approvals, status at glance, and easy to open bugs and jobs.
            </SolutionHeader>
            <Solution>
                <img src={isMobile ? BugsMobile : Bugs} alt='Bugs'
                     style={{maxHeight: isMobile ? undefined : '23rem'}}/>
                <CTAText>
                    See a full <PrimaryButton as="a" href={primaryButtonUrl}>sandbox demo</PrimaryButton> after
                    free sign up.
                </CTAText>
            </Solution>
        </TextContent>
    );
};

export default BelowTheFoldScale;