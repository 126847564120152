import React from 'react';
import Header from '../../../shared/SiteHeader';
import AboveTheFold from "../../../shared/AboveTheFold";
import Pricing from "../../../shared/Pricing";
import Footer from "../../../shared/MiniCenteredFooter";
import BelowTheFold from "../../../shared/BelowTheFold";

function MobileHome() {
    return (
        <>
            <Header />
            <div>
                <AboveTheFold isMobile />
                <BelowTheFold isMobile  />
                <div style={{marginTop: '6rem'}} />
                <Pricing />
            </div>
            <Footer/>
        </>
    );
}

export default MobileHome;