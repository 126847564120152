import React from "react";
import videoMobile from '../assets/swimlanesMobile.mp4';

const BelowTheFoldDemoTeaser = (properties) => {
    const { isMobile } = properties;
    if (isMobile) {
        return (
            <video id='aboveTheFoldVideo' style={{borderRadius: '15px', marginTop: '4rem',
                marginLeft: 'auto', marginRight: 'auto'}} autoPlay muted loop controls
            >
                <source
                    src={videoMobile}
                    type="video/mp4"
                />
                Your browser does not support the video tag.
            </video>
        );
    }
    return (
        <video id='aboveTheFoldVideo' style={{borderRadius: '15px', marginLeft: 'auto', marginRight: 'auto',
            marginTop: '3rem'}} width="1100" height="330" autoPlay muted loop>
            <source
                src={videoMobile}
                type="video/mp4"
            />
            Your browser does not support the video tag.
        </video>
    );
};

export default BelowTheFoldDemoTeaser;