import React from "react";
import tw from "twin.macro";
import Swimlanes from "../assets/images/swimlanes.png";
import SwimlanesMobile from "../assets/images/swimlanesMobile.png";

const TextContent = tw.div`justify-center items-center`;
const Problem = tw.div`w-11/12 mb-2`;
const Solution = tw.div`w-11/12 mt-5`;
const CTAText = tw.div`text-xl md:text-2xl pt-4`;
const SolutionHeader = tw.div`w-11/12 text-primary-500 mt-3`;
const SolutionText = tw.div`w-11/12 text-xl`;
const PrimaryButtonBase = tw.button`px-1 py-1 text-primary-500 border-3 border-primary-500 border-solid hover:bg-primary-500 hover:text-gray-100 focus:outline-none`;
const PrimaryButton = tw(
    PrimaryButtonBase,
)`mt-2 text-xl font-bold inline-block rounded-xl`;
const primaryButtonUrl = "https://production.uclusion.com/?utm_campaign=team#signup";

const BelowTheFoldIssues = (properties) => {
    const { isMobile } = properties;
    return (
        <TextContent style={{display: "flex", flexDirection: "column"}}>
            <Problem>
                <SolutionText style={{display: isMobile ? 'none' : undefined}}>
                    If you can't record your status then you are in a meeting to report it.
                </SolutionText>
            </Problem>
            <SolutionHeader style={{fontSize: isMobile ? '1.5rem' : '1.4rem'}}>
                Approvals, suggestions, questions, blockers, estimates, and reviews are first class citizens.
            </SolutionHeader>
            <Solution>
                <img src={isMobile ? SwimlanesMobile : Swimlanes} alt='Swimlanes'
                     style={{maxHeight: isMobile ? undefined : '34rem', }}/>
                <CTAText>
                    See <em>structured comments</em> in the <PrimaryButton as="a" href={primaryButtonUrl}>sandbox
                    demo</PrimaryButton> after
                    free sign up.
                </CTAText>
            </Solution>
        </TextContent>
    );
};

export default BelowTheFoldIssues;
