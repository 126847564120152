import React from "react";
import tw from "twin.macro";
import Unfurl from "../assets/images/slack-unfurl.png";

const TextContent = tw.div`justify-center items-center mr-2 ml-5`;
const Problem = tw.div`w-11/12 mb-5`;
const Solution = tw.div`w-11/12 mt-8`;
const ProblemText = tw.div`w-11/12 text-xl md:text-2xl`;
const CTAText = tw.div`text-xl md:text-2xl mb-2`;
const SolutionHeader = tw.div`w-full text-3xl md:text-5xl text-primary-500`;
const SolutionText = tw.div`w-11/12 text-xl md:text-2xl mb-5`;
const PrimaryButtonBase = tw.button`px-1 py-1 text-primary-500 border-3 border-primary-500 border-solid hover:bg-primary-500 hover:text-gray-100 focus:outline-none`;
const PrimaryButton = tw(
    PrimaryButtonBase,
)`mt-2 text-2xl font-bold inline-block rounded-xl`;
const SlackPicture = tw.img`rounded mb-2`;
const primaryButtonUrl = "https://production.uclusion.com/?utm_campaign=team#signup";

const BelowTheFoldSlack = (properties) => {
    const { isMobile } = properties;
    return (
        <TextContent style={{display:"flex", flexDirection:"column"}}>
            <Problem>
                <SolutionText style={{display: isMobile ? 'none' : undefined}}>
                    Okay for automated alerts, but a chat channel is scribbles on a wall.
                </SolutionText>
                <ProblemText style={{display: isMobile ? 'none' : undefined}}>
                    Co-worker pings and threads are torture.
                </ProblemText>
            </Problem>
            <SolutionHeader style={{fontSize: isMobile ? '1.5rem' : undefined}}>
                Communication and context <em>together</em>.
            </SolutionHeader>
            <Solution>
                <SlackPicture src={Unfurl} />
                <CTAText style={{marginTop: isMobile ? '1.5rem' : undefined}}>
                    See notifications done right in the <PrimaryButton as="a" href={primaryButtonUrl}>sandbox</PrimaryButton> after
                    free sign up.
                </CTAText>
            </Solution>
        </TextContent>
    );
};

export default BelowTheFoldSlack;